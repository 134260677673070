import domReady from "@wordpress/dom-ready";
import { CountUp } from "countup.js/dist/countUp";
import { _x } from "@wordpress/i18n";

domReady(() => {
  const counters = document.querySelectorAll(".counterNumber");

  if (0 < counters.length) {
    counters.forEach((counter) => {
      const number = parseInt(counter.textContent);
      const animation = new CountUp(counter, number, {
        duration: 2,
        separator: _x(",", "number thousand separator", "vtx"),
        enableScrollSpy: true,
        scrollSpyDelay: 300,
      });

      animation.handleScroll();
    });
  }
});
